<template>
  <FinanceFilter
  :settings="settings"
  :filters="this.$store.getters.filtersFinance"
  @changeFilters="changeFilters"
  @saveView="saveView"
  @editView="editView"
  @deleteView="deleteView"
  @exportCSV="exportCSV"
  @exportXLSX="exportXLSX"
  @exportFODonations="exportFODonations"
  @exportPODonations="exportPODonations"
  @showAdvanced="showColumnSelect"
  @reloadData="loadData"
  />
  <transition name="fade">
    <template v-if="showingColumnSelect">
      <ColumnFilter
      :settings="settings"
      :selectedColumns="selectedColumns"
      @changeColumns="changeColumns"
      class="px-3"
      />
    </template>
  </transition>
  <Table
  :settings="settings"
  :items="items"
  :columns="selectedColumns"
  @loadData="loadData"
  @changeSort="changeSort"
  @selectAll="selectAll"
  @deselectAll="deselectAll"
  @selectChangeRow="selectChangeRow"
  />
  <Pagination
  :totalCount="settings.totalCount"
  :perPage="settings.perPage"
  :currentPage="settings.currentPage"
  @page-changed="updatePageData"
  @update:per-page="updatePerPage"
  />
  <LoadingSpinner :csvLoading="csvLoading"/>
</template>

<script>
import Table from "@/components/Table.vue";
import ColumnFilter from '@/components/ColumnFilter.vue';
import FinanceFilter from "@/components/FinanceFilter.vue";
import Pagination from "@/components/Pagination.vue";
import ButtonFinances from '@/components/ButtonFinances.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import axios from "axios";
import { markRaw } from "vue";

export default {
  components: {
    Table,
    FinanceFilter,
    ButtonFinances,
    ColumnFilter,
    Pagination,
    LoadingSpinner,
  },
  created: function () {
    this.setDefaultColumns();
    this.settings.perPage = this.$store.getters.filtersFinance.per_page;
    this.settings.sortBy = this.$store.getters.filtersFinance.sort_by;
    this.settings.sortDirection = this.$store.getters.filtersFinance.sort_order;
    this.loadData(this.$store.getters.filtersFinance.current_page);
  },
  data() {
    return {
      listRequestSource: null,
      showingColumnSelect: false,
      csvLoading: false,
      items: [],
      selectedColumns: [],
      settings: {
        endpoint: "/finance/finances",
        sortBy: "created_at",
        sortDirection: "D",
        settingsType: "finances",
        loading: false,
        totalCount: 0,
        perPage: 50,
        currentPage: 1,
        selected: [],
        columns: [
          {
            col: 2,
            name: "created_at",
            userFriendly: "Datum vložení",
            sortable: true,
            defaultShow: true
          },
          {
            col: 2,
            name: "amount",
            userFriendly: "Částka",
            cellFunction: (item) => { return item.amount + " Kč" },
            default: "0",
            sortable: true,
            defaultShow: true,
            classFunction: (item) => {
              if (item.document_type.financial_type === "V") {
                return "red";
              }
              if (item.document_type.financial_type === "P") {
                return "green";
              }
              return ""
            },
          },
          {
            col: 2,
            name: "to_pay",
            userFriendly: "K úhradě",
            cellFunction: (item) => { return (item.amount - item.amount_paid).toFixed(2) + " Kč" },
            default: "0",
            sortable: true,
            defaultShow: true,
            classFunction: (item) => {
              if ((item.amount - item.amount_paid) === 0) {
                return "green";
              }
              return ""
            },
          },
          {
            col: 2,
            name: "vs",
            userFriendly: "VS",
            default: "-",
            sortable: true,
            defaultShow: true
          },
          {
            col: 2,
            name: "description",
            userFriendly: "Popis",
            default: "-",
            sortable: true,
            defaultShow: true
          },
          {
            col: 2,
            name: "account",
            userFriendly: "Účet",
            cellFunction: (item) => {
              if (item.document_type && item.document_type.financial_type === "P") {
                return ((item.pay_to.account_number !== "" && item.pay_to.bank_number !== "") ? `${item.pay_to.account_number}/${item.pay_to.bank_number}` : "-" )
              } else if (item.document_type && item.document_type.financial_type === "V") {
                return ((item.pay_from.account_number !== "" && item.pay_from.bank_number !== "") ? `${item.pay_from.account_number}/${item.pay_from.bank_number}` : "-" )
              }
            },
            default: "-",
            sortable: false,
          },
          {
            col: 2,
            name: "actions",
            userFriendly: "Akce",
            sortable: false,
            cellComponent: markRaw(ButtonFinances),
            defaultShow: true
          },
        ],
        row: {
          isClickable: this.isClickable,
          name: 'finance-detail'
        }
      }
    }
  },
  methods: {
    isClickable: function (item) {
      return true;
    },
    setDefaultColumns() {
      this.selectedColumns = [];
      if (this.$store.getters.filtersFinance.columns.length > 0) {
        const filteredColumns = this.settings.columns.filter((value) =>{
          return this.$store.getters.filtersFinance.columns.includes(value.name)
        });
        this.selectedColumns = filteredColumns;
      } else {
        for (let i = 0; i < this.settings.columns.length; i++) {
          if (this.settings.columns[i].defaultShow) {
            this.selectedColumns.push(this.settings.columns[i])
          }
        }
      }
    },
    loadData: function (page) {
      // setup
      this.settings.loading = true;

      // cancel last request and create new source
      if (this.listRequestSource !== null) {
        this.listRequestSource.cancel('Operace zrušena uživatelem.');
      }
      this.listRequestSource = axios.CancelToken.source();

      if (page) {
        this.settings.currentPage = page;
      }

      // Send only the data we need on backend
      let filtersCopy = {...this.$store.getters.filtersFinance};
      delete filtersCopy.columns;
      const data = {
        cancelToken: this.listRequestSource.token,
        params: {...filtersCopy}
      };
      this.$store.getters.api.get(this.settings.endpoint, data).then(function (response) {
        const resData = response.data;
        this.items = resData.items;
        this.settings.totalCount = resData._total;

        // cancel loading and remove request source
        this.settings.loading = false;
        this.listRequestSource = null;
      }.bind(this))
      .catch((e) => {
          if (axios.isCancel(e)) {
              console.log("Request canceled", e.message);
          } else {
              console.log(e);
          }
          this.requestRunning = false;
      });
    },
    exportCSV: function (name) {
      this.csvLoading = true;
      const data = {
        params: {
          selected: JSON.stringify(this.settings.selected),
          ...this.$store.getters.filtersFinance
        },
        responseType: 'blob'
      };
      this.$store.getters.api.get(this.settings.endpoint + "/csv", data).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        //create default name
        const filenameArr = this.settings.endpoint.split("/");
        const filename = filenameArr[filenameArr.length - 1]
        link.setAttribute('download', (name ? name : filename) + '.csv');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        this.csvLoading = false;
      });
    },
    exportXLSX: function (name) {
      this.csvLoading = true;
      const data = {
        params: {
          selected: JSON.stringify(this.settings.selected),
          ...this.$store.getters.filtersFinance
        },
        responseType: 'blob'
      };
      this.$store.getters.api.get(this.settings.endpoint + "/xlsx", data).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        //create default name
        const filenameArr = this.settings.endpoint.split("/");
        const filename = filenameArr[filenameArr.length - 1]
        link.setAttribute('download', (name ? name : filename) + '.xlsx');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        this.csvLoading = false;
      });
    },
    exportFODonations: function (name, from, to) {
      this.csvLoading = true;
      const data = {
        params: {
          from: from,
          to: to
        },
        responseType: 'blob'
      };
      this.$store.getters.api.get("/finance/donations/fo-export", data).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        //create default name
        const filenameArr = this.settings.endpoint.split("/");
        const filename = filenameArr[filenameArr.length - 1]
        link.setAttribute('download', (name ? name : filename) + '.xlsx');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        this.csvLoading = false;
      });
    },
    exportPODonations: function (name, from, to) {
      this.csvLoading = true;
      const data = {
        params: {
          from: from,
          to: to
        },
        responseType: 'blob'
      };
      this.$store.getters.api.get("/finance/donations/po-export", data).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        //create default name
        const filenameArr = this.settings.endpoint.split("/");
        const filename = filenameArr[filenameArr.length - 1]
        link.setAttribute('download', (name ? name : filename) + '.xlsx');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        this.csvLoading = false;
      });
    },
    changeFilters: function (key, val) {
      this.$store.commit("setFiltersFinance", { key, value: val });
      this.$store.commit("setFiltersFinance", { key: "current_page", value: 1 });
      this.settings.currentPage = 1;
      if (key === "per_page") {
        this.settings.perPage = val;
      }
      if (key === "sort_by") {
        this.settings.sortBy = val;
      }
      if (key === "sort_order") {
        this.settings.sortDirection = val;
      }
      if (key === "columns") {
        if (val.length > 0) {
          const filteredColumns = this.settings.columns.filter((value) =>{
            return val.includes(value.name)
          });
          this.selectedColumns = filteredColumns;
        } else {
          this.setDefaultColumns();
        }
      }
    },
    changeColumns: function (columns) {
      const selectedColNames = [];
      columns.forEach((column) => {
        selectedColNames.push(column.name);
      });
      this.$store.commit("setFiltersFinance", { key: "columns", value: selectedColNames });
      this.selectedColumns = columns;
    },
    changeSort: function (column, direction) {
      this.$store.commit("setFiltersFinance", { key: "sort_by", value: column });
      this.settings.sortBy = column;
      this.$store.commit("setFiltersFinance", { key: "sort_order", value: direction });
      this.settings.sortDirection = direction;
      this.$store.commit("setFiltersFinance", { key: "current_page", value: 1 });
      this.settings.currentPage = 1;
      this.loadData();
    },
    selectChangeRow: function (item_id, state) {
      if (state) {
        this.settings.selected.push(item_id)
      }
      else {
        const index = this.settings.selected.indexOf(item_id);
        this.settings.selected.splice(index, 1);
      }
    },
    selectAll: function () {
      this.deselectAll();
      for (let i = 0; i < this.items.length; i++) {
        this.settings.selected.push(this.items[i].id)
      }
    },
    deselectAll: function () {
      this.settings.selected = [];
    },
    updatePageData(page) {
      this.$store.commit("setFiltersFinance", { key: "current_page", value: page });
      this.settings.currentPage = page;
      this.loadData();
    },
    updatePerPage(newPerPage) {
      this.$store.commit("setFiltersFinance", { key: "per_page", value: newPerPage });
      this.settings.perPage = newPerPage;
      this.$store.commit("setFiltersFinance", { key: "current_page", value: 1 });
      this.settings.currentPage = 1;
      this.loadData();
    },
    saveView: function (name) {
      const userId = this.$store.getters.user.user.id;
      const view = this.buildViewData();
      view.name = name;
      this.$store.getters.api.post("/user/" + userId + "/settings", view).then(function (response) {
        // reload store settings data?
      }.bind(this));
    },
    editView: function (id) {
      const userId = this.$store.getters.user.user.id;
      const view = this.buildViewData();
      const oldView = this.$store.getters.settings.find((view) => view.id == id);
      view.id = id;
      view.name = oldView.name;
      this.$store.getters.api.put("/user/" + userId + "/settings", view).then(function (response) {
        // reload store settings data?
      }.bind(this));
    },
    deleteView: function (id) {
      const userId = this.$store.getters.user.user.id;
      this.$store.getters.api.delete("/user/" + userId + "/settings/" + id).then(function (response) {
        // reload store settings data?
      }.bind(this));
    },
    buildViewData: function () {
      const selectedColNames = [];
      this.selectedColumns.forEach((column) => {
        selectedColNames.push(column.name);
      });
      this.$store.commit("setFiltersFinance", { key: 'columns', value: selectedColNames });
      this.$store.commit("setFiltersFinance", { key: 'per_page', value: this.settings.perPage });
      this.$store.commit("setFiltersFinance", { key: 'sort_by', value: this.settings.sortBy });
      this.$store.commit("setFiltersFinance", { key: 'sort_order', value: this.settings.sortDirection });
      const view = {
        id: null,
        name: null,
        settings_type: this.settings.settingsType,
        group: null,
        data: this.$store.getters.filtersFinance
      };
      return view;
    },
    showColumnSelect: function () {
      this.showingColumnSelect = !this.showingColumnSelect;
    },
  },
}
</script>

<style scoped lang="scss">
</style>
