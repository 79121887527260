<template>
  <div class="form-group row px-3">
    <div class="d-flex justify-content-end flex-wrap pt-4">
      <input
        type="text"
        class="form-control flex-fill w-25 m-2"
        v-model="debouncedSearchValue"
        @input="debouncedSearch"
        placeholder="HLEDAT VS, popis"
      />
      <select class="form-select w-auto flex-fill m-2" v-model="income">
        <option value="A" selected>Příjmy i výdaje</option>
        <option value="P">Příjmy</option>
        <option value="V">Výdaje</option>
      </select>
      <multiselect
        v-model="account"
        :options="accounts_data"
        :multiple="true"
        :close-on-select="true"
        :clear-on-select="false"
        :preserve-search="true"
        placeholder="Vyberte účet"
        label="name"
        track-by="id"
        class="m-2 w-25"
        :limit="1"
        :limitText="limitText"
        :preselect-first="false">
      </multiselect>
      <multiselect
        v-model="document_type"
        :options="documentTypes"
        :multiple="true"
        :close-on-select="true"
        :clear-on-select="false"
        :preserve-search="true"
        placeholder="Vyberte druh dokumentů"
        label="name"
        track-by="id"
        class="m-2 w-25"
        :limit="1"
        :limitText="limitText"
        :preselect-first="false">
      </multiselect>
      <multiselect
        v-model="chapter"
        :options="chapters"
        :multiple="true"
        :close-on-select="true"
        :clear-on-select="false"
        :preserve-search="true"
        placeholder="Vyberte kapitolu"
        label="name"
        track-by="id"
        class="m-2 w-25"
        :limit="1"
        :limitText="limitText"
        :preselect-first="false">
      </multiselect>
      <multiselect
        v-model="region"
        :options="regions"
        :multiple="true"
        :close-on-select="true"
        :clear-on-select="false"
        :preserve-search="true"
        placeholder="Vyberte org. složku"
        label="name"
        track-by="id"
        class="m-2 w-25"
        :limit="1"
        :limitText="limitText"
        :preselect-first="false">
      </multiselect>
      <multiselect
        v-model="document_state"
        :options="states"
        :multiple="true"
        :close-on-select="true"
        :clear-on-select="false"
        :preserve-search="true"
        placeholder="Vyberte stav"
        label="name"
        track-by="id"
        class="m-2 w-25"
        :limit="1"
        :limitText="limitText"
        :preselect-first="false">
      </multiselect>
      <select class="form-select w-auto flex-fill m-2" v-model="view" @change="setFields">
        <option v-for="view in views" :key="view.id" :value="view">{{ view.name }}</option>
      </select>
      <div class="d-inline-flex">
        <button class="btn btn-primary d-inline-flex m-2" @click="showAdvanced()">
          <icon icon="ChevronDownWhite" />
          &nbsp;Pokročilé
        </button>
        <button type="button" class="btn btn-light m-2" @click="setFields">Výchozí</button>
        <button type="button" class="btn btn-danger dropdown m-2" id="views" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
          v-if="$store.getters.hasPerm('add_settings') || $store.getters.hasPerm('change_settings') || $store.getters.hasPerm('add_finances')">+</button>
        <div class="dropdown-menu" aria-labelledby="views">
          <router-link class="dropdown-item" :to="{ name: 'finance-add' }"
            v-if="$store.getters.hasPerm('add_finances')">
            Přidat nový doklad
          </router-link>
          <a class="dropdown-item"
            data-bs-toggle="modal"
            data-bs-target="#approveSelected"
            href="#"
            v-if="$store.getters.hasPerm('approve_finances') && this.settings.selected.length > 0">
            Schválit označené
          </a>
          <a class="dropdown-item"
            data-bs-toggle="modal"
            data-bs-target="#rejectSelected"
            href="#"
            v-if="$store.getters.hasPerm('approve_finances') && this.settings.selected.length > 0">
            Zamítnout označené
          </a>
          <a class="dropdown-item"
            data-bs-toggle="modal"
            data-bs-target="#paySelected"
            href="#"
            v-if="$store.getters.hasPerm('pay_finances') && this.settings.selected.length > 0">
            Zaplatit označené
          </a>
          <a class="dropdown-item"
            data-bs-toggle="modal"
            data-bs-target="#editView"
            href="#"
            v-if="$store.getters.hasPerm('change_settings') && this.view.id">
            Aktualizovat tento pohled
          </a>
          <a class="dropdown-item"
            data-bs-toggle="modal"
            data-bs-target="#addView"
            href="#"
            v-if="$store.getters.hasPerm('add_settings')">
            Uložit jako nový pohled
          </a>
          <a class="dropdown-item"
            data-bs-toggle="modal"
            data-bs-target="#deleteView"
            href="#"
            v-if="$store.getters.hasPerm('delete_settings') && this.view.id">
            Smazat tento pohled
          </a>
          <a class="dropdown-item"
            data-bs-toggle="modal"
            data-bs-target="#csv"
            href="#"
            v-if="$store.getters.hasPerm('export_finances')">
            Export do CSV
          </a>
          <a class="dropdown-item"
            data-bs-toggle="modal"
            data-bs-target="#xlsx"
            href="#"
            v-if="$store.getters.hasPerm('export_finances')">
            Export do XLSX
          </a>
          <a class="dropdown-item"
            data-bs-toggle="modal"
            data-bs-target="#exportFODonations"
            href="#"
            v-if="$store.getters.hasPerm('donations_export')">
            Export darů - fyzické osoby
          </a>
          <a class="dropdown-item"
            data-bs-toggle="modal"
            data-bs-target="#exportPODonations"
            href="#"
            v-if="$store.getters.hasPerm('donations_export')">
            Export darů - pravnické osoby
          </a>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div v-if="showingAdvanced" class="col-12">
        <div class="d-flex flex-wrap flex-lg-nowrap align-items-center pb-4">
          <multiselect
            v-model="year"
            :options="years_data"
            :multiple="true"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Vyberte rok"
            label="year"
            track-by="year"
            class="m-2 w-25"
            :limit="1"
            :limitText="limitText"
            :preselect-first="false">
          </multiselect>
          <multiselect
            v-model="subchapter"
            :options="subchapters"
            :multiple="true"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Vyberte podkapitolu"
            label="name"
            track-by="id"
            class="m-2 w-25"
            :limit="1"
            :limitText="limitText"
            :preselect-first="false">
          </multiselect>
          <multiselect
            v-model="approved_by"
            :options="approved_by_data"
            :multiple="true"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Schválil"
            label="name"
            track-by="id"
            class="m-2 w-25"
            :limit="1"
            :limitText="limitText"
            :preselect-first="false">
          </multiselect>
          <select class="form-select w-auto flex-fill m-2" v-model="paired">
            <option value="A" selected>Párování s bankou - vše</option>
            <option value="Y">Spárováno s bankou</option>
            <option value="N">Nespárováno s bankou</option>
          </select>
        </div>
      </div>
    </transition>
    <Popup
      :header="'Zadejte název nového pohledu'"
      :editable="true"
      id="addView"
      @save-data="saveView"
      v-if="$store.getters.hasPerm('add_settings')"
    />
    <Popup
      :header="'Schválit označené doklady'"
      :editable="false"
      :body="'Označené doklady budou hromadně schváleny.'"
      :button="'Schválit'"
      id="approveSelected"
      @save-data="approveDocuments"
      v-if="$store.getters.hasPerm('approve_finances')"
    />
    <Popup
      :header="'Zamítnout označené doklady'"
      :editable="false"
      :body="'Označené doklady budou hromadně zamítnuty.'"
      :button="'Zamítnout'"
      id="rejectSelected"
      @save-data="rejectDocuments"
      v-if="$store.getters.hasPerm('approve_finances')"
    />
    <Popup
      :header="'Zaplatit označené doklady'"
      :editable="false"
      :body="'Označené doklady budou hromadně zaplaceny.'"
      :button="'Zaplatit'"
      id="paySelected"
      @save-data="payDocuments"
      v-if="$store.getters.hasPerm('pay_finances')"
    />
    <Popup
      :header="'Aktualizovat pohled'"
      :editable="false"
      id="editView"
      :body="user_id == this.$store.getters.user.id ? `Přepsat nastavení pohledu ${this.view.name}?` : 'Nastavení lze měnit pouze u vlastních pohledů.'"
      :button="user_id == this.$store.getters.user.id ? 'Přepsat' : 'OK'"
      @save-data="editView"
      v-if="$store.getters.hasPerm('change_settings')"
    />
    <Popup
      :header="'Smazat pohled'"
      :editable="false"
      id="deleteView"
      :body="user_id == this.$store.getters.user.id ? `Opravdu smazat pohled ${this.view.name}?` : 'Mazat lze pouze vlastní pohledy.'"
      :button="user_id == this.$store.getters.user.id ? 'Smazat' : 'OK'"
      @save-data="deleteView"
      v-if="$store.getters.hasPerm('delete_settings')"
    />
    <Popup
      :header="'Export do CSV'"
      :editable="true"
      id="csv"
      :button="'Exportovat'"
      @save-data="exportCSV(csvName)"
      v-if="$store.getters.hasPerm('export_finances')">
      <template v-slot:editor>
        <div class="mb-3">
          <p class="m-2">Exportují se označené řádky. Pokud nic neoznačíte, exportují se všechny záznamy podle aktuálních filtrů.</p>
          <label for="name" class="m-2">Zadejte název souboru:</label>
          <input id="name" type="text" class="form-control my-2" v-model="csvName">
        </div>
      </template>
    </Popup>
    <Popup
      :header="'Export do XLSX'"
      :editable="true"
      id="xlsx"
      :button="'Exportovat'"
      @save-data="exportXLSX(csvName)"
      v-if="$store.getters.hasPerm('export_finances')">
      <template v-slot:editor>
        <div class="mb-3">
          <p class="m-2">Exportují se označené řádky. Pokud nic neoznačíte, exportují se všechny záznamy podle aktuálních filtrů.</p>
          <label for="name" class="m-2">Zadejte název souboru:</label>
          <input id="name" type="text" class="form-control my-2" v-model="csvName">
        </div>
      </template>
    </Popup>
    <Popup
      :header="'Export darů - FO'"
      :editable="true"
      id="exportFODonations"
      :button="'Exportovat'"
      @save-data="exportFODonations(csvName, exportFrom, exportTo)"
      v-if="$store.getters.hasPerm('donations_export')">
      <template v-slot:editor>
        <div class="mb-3">
          <label for="name" class="m-2">Zadejte název souboru:</label>
          <input id="name" type="text" class="form-control my-2" v-model="csvName">
          <label for="from" class="m-2">Exportovat od:</label>
          <input id="from" type="date" class="form-control my-2" v-model="exportFrom">
          <label for="to" class="m-2">Exportovat do:</label>
          <input id="to" type="date" class="form-control my-2" v-model="exportTo">
        </div>
      </template>
    </Popup>
    <Popup
      :header="'Export darů - PO'"
      :editable="true"
      id="exportPODonations"
      :button="'Exportovat'"
      @save-data="exportPODonations(csvName, exportFrom, exportTo)"
      v-if="$store.getters.hasPerm('donations_export')">
      <template v-slot:editor>
        <div class="mb-3">
          <label for="name" class="m-2">Zadejte název souboru:</label>
          <input id="name" type="text" class="form-control my-2" v-model="csvName">
          <label for="from" class="m-2">Exportovat od:</label>
          <input id="from" type="date" class="form-control my-2" v-model="exportFrom">
          <label for="to" class="m-2">Exportovat do:</label>
          <input id="to" type="date" class="form-control my-2" v-model="exportTo">
        </div>
      </template>
    </Popup>
  </div>
</template>

<script>
import Popup from '@/components/Popup.vue';
import Multiselect from 'vue-multiselect'

export default {
  props: {
    settings: {
        type: Object,
        required: true
    },
    filters: {
        type: Object,
        required: true
    },
  },
  components: {
    Popup,
    Multiselect
  },
  mounted: function () {
    this.debouncedSearchValue = this.search;
    this.getFullObjects().then(() => {
      // Do not trigger changeFilters method in parent and reload data until watchers deal with the changes
      this.$nextTick(() => {
        this.noReload = false;
      });
    });
  },
  data() {
    return {
      view: {
        id: null,
        name: "Výchozí pohled",
        settings_type: "finances",
        user: null,
        group: null,
        data: null
      },
      accounts_data: [],
      years_data: [],
      approved_by_data: [],
      reloadTimer: null,
      noReload: true,
      csvName: "",
      exportFrom: null,
      exportTo: null,
      showingAdvanced: false,
      debouncedSearchValue: "",
      debouncedSearch: this.debounce(this.updateSearch),
      ...this.filters
    };
  },
  computed: {
    views() {
      const settings = this.$store.getters.settings;
      const views = settings.filter(view => view.settings_type === "finances");
      views.unshift({
        id: null,
        name: "Výchozí pohled",
        settings_type: "finances",
        user: null,
        group: null,
        data: null
      });
      return views;
    },
    user_id() {
      if (this.view) {
        return this.view.user;
      }
      return null;
    },
    regions() {
      const countries = this.$store.getters.countries;
      const regions = this.$store.getters.regions;
      const orgAll = countries.concat(regions);
      return orgAll;
    },
    documentTypes() {
      const allTypes = this.$store.getters.docTypes;
      const docTypes = [];
      if (this.income === "A") {
        return allTypes;
      }
      allTypes.forEach((docType) => {
        if (docType.financial_type === this.income) {
          docTypes.push(docType);
        }
      });
      return docTypes;
    },
    chapters() {
      const allChapters = this.$store.getters.chapters;
      const chapters = [];
      if (this.document_type.length === 0) {
        allChapters.forEach((chapter) => {
          if ((this.income === "A" || chapter.financial_type === this.income) && !chapter.parent) {
            chapters.push(chapter);
          }
        });
        return chapters;
      }
      allChapters.forEach((chapter) => {
        const DocumentType = [];
        this.document_type.forEach((type) => {
          DocumentType.push(type.financial_type);
        });
        if (DocumentType.includes(chapter.financial_type) && (this.income === "A" || chapter.financial_type === this.income) && !chapter.parent) {
          chapters.push(chapter);
        }
      });
      return chapters;
    },
    subchapters() {
      const allChapters = this.$store.getters.chapters;
      const subchapters = [];
      if (!this.chapter || this.chapter.length === 0) {
        allChapters.forEach((chapter) => {
          if (chapter.parent) {
            subchapters.push(chapter);
          }
        });
      } else {
        allChapters.forEach((chapter) => {
          if (chapter.parent && this.filters.chapter.includes(chapter.parent.id)) {
            subchapters.push(chapter);
          }
        });
      }
      return subchapters;
    },
    states() {
      const allStates = this.$store.getters.docStates;
      const states = [];
      if (this.document_type.length === 0) {
        if (this.income === "A") {
          return allStates;
        }
        allStates.forEach((state) => {
          if (state.document_type === this.income || !state.document_type) {
            states.push(state);
          }
        });
        return states;
      }
      allStates.forEach((state) => {
        if (!state.document_type) {
          states.push(state);
        } else {
          const DocumentType = [];
          this.document_type.forEach((type) => {
            DocumentType.push(type.financial_type);
          });
          if (DocumentType.includes(state.document_type) && (this.income === "A" || state.document_type === this.income)) {
            states.push(state);
          }
        }
      });
      return states;
    }
  },
  watch: {
    account: {
      handler: function (val) {
        if (!this.noReload) {
          const ids = [];
          val.forEach((obj) => {
            ids.push(obj.id)
          });
          this.changeFilters("account", ids);
        }
      },
      deep: true,
    },
    document_type: {
      handler: function (val) {
        if (!this.noReload) {
          const ids = [];
          const DocumentType = [];
          val.forEach((obj) => {
            ids.push(obj.id);
            DocumentType.push(obj.financial_type);
          });
          this.changeFilters("document_type", ids);
          if (this.document_state.length > 0 && ids.length > 0) {
            const states = [];
            this.document_state.forEach((state) => {
              if (!state.document_type || DocumentType.includes(state.document_type)) {
                states.push(state);
              }
            });
            this.document_state = states;
          }
          if (this.chapter && this.chapter.length > 0 && ids.length > 0) {
            const chapters = [];
            this.chapter.forEach((chapter) => {
              if (!chapter.parent && DocumentType.includes(chapter.financial_type)) {
                chapters.push(chapter);
              }
            });
            this.chapter = chapters;
          }
        }
      },
      deep: true,
    },
    chapter: {
      handler: function (val) {
        if (!this.noReload) {
          const ids = [];
          if (val) {
            val.forEach((obj) => {
              ids.push(obj.id)
            });
            this.changeFilters("chapter", ids);
            if (this.subchapter.length > 0 && ids.length > 0) {
              const subchapters = [];
              this.subchapter.forEach((subchapter) => {
                if (subchapter.parent && ids.includes(subchapter.parent.id)) {
                  subchapters.push(subchapter);
                }
              });
              this.subchapter = subchapters;
            }
          }
        }
      },
      deep: true,
    },
    subchapter: {
      handler: function (val) {
        if (!this.noReload) {
          const ids = [];
          val.forEach((obj) => {
            ids.push(obj.id)
          });
          this.changeFilters("subchapter", ids);
        }
      },
      deep: true,
    },
    region: {
      handler: function (val) {
        if (!this.noReload) {
          const ids = [];
          val.forEach((obj) => {
            ids.push(obj.id)
          });
          this.changeFilters("region", ids);
        }
      },
      deep: true,
    },
    document_state: {
      handler: function (val) {
        if (!this.noReload) {
          const ids = [];
          val.forEach((obj) => {
            ids.push(obj.id)
          });
          this.changeFilters("document_state", ids);
        }
      },
      deep: true,
    },
    year: {
      handler: function (val) {
        if (!this.noReload) {
          const years = [];
          val.forEach((obj) => {
            years.push(obj.year)
          });
          this.changeFilters("year", years);
        }
      },
      deep: true,
    },
    approved_by: {
      handler: function (val) {
        if (!this.noReload) {
          const ids = [];
          val.forEach((obj) => {
            ids.push(obj.id)
          });
          this.changeFilters("approved_by", ids);
        }
      },
      deep: true,
    },
    paired: {
      handler: function (val) {
        if (!this.noReload) {
          this.changeFilters("paired", val);
        }
      },
      deep: true,
    },
    income: {
      handler: function (val) {
        if (!this.noReload) {
          this.changeFilters("income", val);
          if (val !== "A") {
            if (this.chapter && this.chapter.length > 0) {
              const chapters = [];
              this.chapter.forEach((chapter) => {
                if (!chapter.parent && chapter.financial_type === val) {
                  chapters.push(chapter);
                }
              });
              this.chapter = chapters;
            }
            if (this.document_type.length > 0) {
              const docTypes = [];
              this.document_type.forEach((docType) => {
                if (docType.financial_type === val) {
                  docTypes.push(docType);
                }
              });
              this.document_type = docTypes;
            }
            if (this.document_state.length > 0) {
              const states = [];
              this.document_state.forEach((state) => {
                if (!state.document_type || state.document_type === val) {
                  states.push(state);
                }
              });
              this.document_state = states;
            }
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    loadFilters: function () {
      return this.$store.getters.api.get("/finance/filters").then(response => {
        this.years_data = response.data.years.sort((a, b) => (b.year - a.year));
        this.approved_by_data = response.data.approved_by.sort((a, b) => a.name.localeCompare(b.name)); // Sort states by name
      });
    },
    loadAccounts: function () {
      return this.$store.getters.api.get("/finance/accounts").then(response => {
        this.accounts_data = [];
        const sorted = response.data.items.sort((a, b) => {
          if (a.name === null && b.name === null) {
            return 0;
          } else if (a.name === null) {
            return -1;
          } else if (b.name === null) {
            return 1;
          } else {
            return a.name.localeCompare(b.name);
          }
        }); // Sort accounts by name
        sorted.forEach((account) => {
          if (account.account_type === 'intern') {
            this.accounts_data.push(account);
          }
        })
      });
    },
    changeFilters: function (key, val) {
      this.$emit('changeFilters', key, val);

      // Wait with reloading data in case more filters are being changed at once
      if(this.reloadTimer) {
        clearTimeout(this.reloadTimer);
      }
      this.reloadTimer = setTimeout(() => {
        this.reloadData();
      }, 100);
    },
    showAdvanced: function () {
      this.showingAdvanced = !this.showingAdvanced;
      this.$emit('showAdvanced');
    },
    limitText: function (count) {
      if (count <= 4) {
        return `a ${count} další`;
      }
      return `a ${count} dalších`;
    },
    debounce(func, timeout = 500) {
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(this, args);
        }, timeout);
      };
    },
    updateSearch() {
      this.changeFilters("search", this.debouncedSearchValue);
    },
    resetFields: function() {
      this.changeFilters("search", "");
      this.debouncedSearchValue = "";
      this.changeFilters("account", []);
      this.account = [];
      this.changeFilters("document_type", []);
      this.document_type = [];
      this.changeFilters("chapter", []);
      this.chapter = [];
      this.changeFilters("region", []);
      this.region = [];
      this.changeFilters("document_state", []);
      this.document_state = [];
      this.changeFilters("subchapter", []);
      this.subchapter = [];
      this.changeFilters("year", []);
      this.year = [];
      this.changeFilters("approved_by", []);
      this.approved_by = [];
      this.changeFilters("columns", []);
      this.changeFilters("per_page", 50);
      this.changeFilters("sort_by", "created_at");
      this.changeFilters("sort_order", "D");
      this.changeFilters("paired", "A");
      this.changeFilters("income", "A");
      this.paired = "A";
      this.income = "A";
      this.view = {
        id: null,
        name: "Výchozí pohled",
        settings_type: "finances",
        user: null,
        group: null,
        data: null
      };
    },
    setFields: function () {
      this.noReload = true;
      if (!this.view || !this.view.data) {
        this.resetFields();
        return;
      }
      this.changeFilters("search", this.view.data.search || "");
      this.debouncedSearchValue = this.view.data.search || "";
      this.changeFilters("per_page", this.view.data.per_page || 50);
      this.changeFilters("sort_by", this.view.data.sort_by || "created_at");
      this.changeFilters("sort_order", this.view.data.sort_order || "D");
      this.changeFilters("paired", this.view.data.paired || "A");
      this.changeFilters("income", this.view.data.income || "A");
      this.changeFilters("account", this.view.data.account || []);
      this.changeFilters("document_type", this.view.data.document_type || []);
      this.changeFilters("chapter", this.view.data.chapter || []);
      this.changeFilters("subchapter", this.view.data.subchapter || []);
      this.changeFilters("region", this.view.data.region || []);
      this.changeFilters("year", this.view.data.year || []);
      this.changeFilters("approved_by", this.view.data.approved_by || []);
      this.changeFilters("document_state", this.view.data.document_state || []);
      this.changeFilters("columns", this.view.data.columns || []);

      this.getFullObjects().then(() => {
        // Do not trigger changeFilters method in parent and reload data until watchers deal with the changes
        this.$nextTick(() => {
          this.noReload = false;
        });
      });
    },
    getFullObjects: async function () {
      // get full objects for filter values
      this.getAccounts();
      this.getFilters();
      this.document_type = [];
      this.filters.document_type.forEach((docType) => {
        this.document_type.push(this.$store.getters.docTypes.find((item) => item.id === docType));
      });
      this.chapter = [];
      this.filters.chapter.forEach((chapter) => {
        this.chapter.push(this.$store.getters.chapters.find((item) => item.id === chapter));
      });
      this.subchapter = [];
      this.filters.subchapter.forEach((subchapter) => {
        this.subchapter.push(this.$store.getters.chapters.find((item) => item.id === subchapter));
      });
      this.region = [];
      this.filters.region.forEach((region) => {
        this.region.push(this.regions.find((item) => item.id === region));
      });
      this.document_state = [];
      this.filters.document_state.forEach((docState) => {
        this.document_state.push(this.$store.getters.docStates.find((item) => item.id === docState));
      });
      this.paired = this.filters.paired;
      this.income = this.filters.income;
    },
    getAccounts: function () {
      this.account = [];
      this.loadAccounts().then(() => {
        this.filters.account.forEach((account) => {
          this.account.push(this.accounts_data.find((item) => item.id === account));
        });
      });
    },
    getFilters: function () {
      this.year = [];
      this.approved_by = [];
      this.loadFilters().then(() => {
        this.filters.year.forEach((year) => {
          this.year.push(this.years_data.find((item) => item.year === year));
        });
        this.filters.approved_by.forEach((approved) => {
          this.approved_by.push(this.approved_by_data.find((item) => item.id === approved));
        });
      });
    },
    approveDocuments: function () {
      if (this.settings.selected.length === 0) {
        return;
      }
      const data = {
        ids: this.settings.selected,
      };
      this.$store.getters.api.put('/finance/approve', data).then(function (_response) {
        this.$parent.loadData();
      }.bind(this)).catch(function (error) {
        console.log(error);
      });
    },
    rejectDocuments: function () {
      if (this.settings.selected.length === 0) {
        return;
      }
      const data = {
        ids: this.settings.selected,
      };
      this.$store.getters.api.put('/finance/reject', data).then(function (_response) {
        this.$parent.loadData();
      }.bind(this)).catch(function (error) {
        console.log(error);
      });
    },
    payDocuments: function () {
      if (this.settings.selected.length === 0) {
        return;
      }
      const data = {
        ids: this.settings.selected,
      };
      this.$store.getters.api.put('/finance/pay', data).then(function (_response) {
        this.$parent.loadData();
      }.bind(this)).catch(function (error) {
        console.log(error);
      });
    },
    exportCSV: function (name) {
      this.$emit('exportCSV', name);
    },
    exportXLSX: function (name) {
      this.$emit('exportXLSX', name);
    },
    exportFODonations: function (name, from, to) {
      this.$emit('exportFODonations', name, from, to);
    },
    exportPODonations: function (name, from, to) {
      this.$emit('exportPODonations', name, from, to);
    },
    saveView: function (name) {
      this.$emit('saveView', name);
      this.reloadSettings();
    },
    editView: function () {
      if (this.user_id == this.$store.getters.user.id) {
        this.$emit('editView', this.view.id);
        this.reloadSettings();
      }
    },
    deleteView: function () {
      if (this.user_id == this.$store.getters.user.id) {
        this.$emit('deleteView', this.view.id);
        this.reloadSettings();
        this.resetFields();
      }
    },
    reloadSettings: function () {
      setTimeout(function () {
        this.$store.commit("reloadSettings");
      }.bind(this), 500);
    },
    reloadData: function () {
      this.$emit('reloadData');
    }
  },
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/_variables.scss";

.btn-light {
  background-color: $yellow-light;
  border-color: $yellow-main;
}

.btn-light:hover {
  background-color: $yellow-main;
  border-color: $yellow-main;
}

.btn-light:active, .btn-light:target {
  background-color: $yellow-main !important;
  border-color: $yellow-main !important;
}
</style>